<template>
  <v-dialog v-model="value" persistent scrollable max-width="50vw">
    <v-overlay :value="updating">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
        <h4 class="mt-4">Updating products, please wait....</h4>
      </div>
    </v-overlay>
    <v-card >
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{edit ? `Edit Artwork - ${artwork.artworkCode}` : "New Artwork"}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="mb-2">
        Artwork Details
      </v-card-title>
      <v-card-text class="pt-2">
      <v-form ref="formbase" v-model="formValid" lazy-validation @submit.prevent>
      <v-row>
        <v-col class="col-6">
          <v-select outlined dense :rules="[rules.required]" :disabled="edit" :items="artists" label="Artist" v-model="artwork.artistCode" item-text="name" item-value="artistCode" @change="handleArtistChange()"></v-select>
        </v-col>
        <v-col class="col-6">
          <v-select outlined dense :rules="[rules.required]" :disabled="editSalesModel" :items="salesModels" label="Pricing Type" v-model="artwork.salesModel"></v-select>
        </v-col>
        <template v-if="artwork?.artistCode">
          <v-col class="col-6">
            <v-select outlined dense :items="regions" label="Region" v-model="artwork.region"></v-select>
          </v-col>
          <v-col class="col-6">
            <v-select outlined dense :rules="[rules.required]" :items="subjectMatter" @input="log" label="Subject Matter" v-model="artwork.subjectMatter"></v-select>
          </v-col>
          <v-col class="col-12">
            <v-text-field
              v-model="artwork.title"
              label="Title"
              @input="log"
              :rules="[rules.required]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col class="col-4">
            <v-text-field
              v-model="artwork.height"
              label="Height"
              @input="log"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col class="col-4">
            <v-text-field
              v-model="artwork.width"
              label="Width"
              @input="log"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col class="col-4">
            <v-select outlined dense :items="['mm', 'in']" label="Units" v-model="artwork.units" @input="log"></v-select>
          </v-col>
          <v-col class="col-8">
            <v-file-input
              label="Artwork Image"
              v-model="selectedImage"
              prepend-icon="mdi-image"
              accept="image/*"
              @change="handleImageUpdate"
            ></v-file-input>
          </v-col>
        </template>
      </v-row>
      </v-form>
      <img id="px300"></img>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isDirty || !formValid" color="success" class="mr-4" @click.native="saveArtworkAndNew()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ArtistApi from '@/api/admin/artist'
import ArtworkApi from '@/api/artwork'
import Regions from '@/constants/regions'
import SubjectMatter from '@/constants/subjectMatter'
import SalesModels from '@/constants/salesModels'
import { resizeImage, readFileAsDataURL, dataURLtoFile, fetchBlob } from '@/utilities/imageUtilities'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/artwork`

export default {
  props: {
    value: Boolean,
    edit: Boolean,
    data: Object
  },
  components: {
  },
  async mounted() {
  },
  data() {
    return {
      productTypes: [],
      salesModels: SalesModels,
      artists: [],
      updating: false,
      isDirty: false,
      regions: Regions,
      subjectMatter: SubjectMatter,
      selectedImage: null,
      formValid: true,
      artwork: this.data || { units: "mm"},
      editSalesModel: false,
      rules: {
        imageRequired: () => {
          // if (this.artwork.image) return false
          return !this.artwork.image || 'An artwork image is required'
        },
        required: (value) => !!value || 'Required.'
      },
    }
  },
  computed: {
  },
  async mounted() {
    if (this.edit) {
      const px300=document.getElementById('px300');
      px300.src=(`${process.env.VUE_APP_IMG_URL}/300px/${this.data.image}`);
    }
  },
  async created() {
    const artworkProducts = await ArtworkApi.getArtworkProducts(this.data?.artworkCode)
    this.editSalesModel = artworkProducts.data.length > 0
    ArtistApi.loadAllArtists().then((results) => {
      this.artists = results.data
      this.loadingData = false
    }).catch(
      err => {
        console.log('err')
        this.loadingData = false
      }    )
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async handleImageUpdate() {
      const px300=document.getElementById('px300');
      if (this.selectedImage) {
        const b64str = await readFileAsDataURL(this.selectedImage);
        const _IMG300 = await resizeImage(b64str, { maxHeight: 300, maxWidth: 300 } );
        px300.src=(_IMG300.url);
      } else {
        px300.src=''
      }
      this.isDirty = true
    },
    async handleArtistChange() {
      if (!this.edit) {
        const nextSequence = await ArtworkApi.nextArtworkSequence(this.artwork.artistCode)
        this.artwork.skuSequence = nextSequence.data
        this.artwork.artworkCode = `${this.artwork.artistCode}-${nextSequence.data}`
        this.artwork.image = `${this.artwork.artworkCode}.jpg`
      }
    },
    async saveArtworkAndNew() {
      this.loadingData = true
      if ( this.selectedImage) {
        const b64str = await readFileAsDataURL(this.selectedImage);
        const _IMG1500 = await resizeImage(b64str, { maxHeight: 1500, maxWidth: 1500 } );
        const _IMG300 = await resizeImage(b64str, { maxHeight: 300, maxWidth: 300 } );
        const _IMG100 = await resizeImage(b64str, { maxHeight: 100, maxWidth: 100 } );
        const px300=document.getElementById('px300');
        px300.src=(_IMG300.url);
        ArtworkApi.uploadArtworkImage( { path: '1500px', name: this.artwork.image, file: dataURLtoFile(_IMG1500.url) })
        ArtworkApi.uploadArtworkImage( { path: '300px', name: this.artwork.image, file: dataURLtoFile(_IMG300.url) })
        ArtworkApi.uploadArtworkImage( { path: '100px', name: this.artwork.image, file: dataURLtoFile(_IMG100.url) })
      }
      this.updating = true
      ArtworkApi.saveArtwork({ ...this.artwork}).then((results) => {
        this.updating = false
        this.$emit('close', { artworkCode: this.artwork.artworkCode, edited: true })
        this.isDirty = false
      }).catch(
        err => {
          console.log('err')
          this.loadingData = false
        }
      )
    },
    log(v){
      // this.variant.productTypeVariantCode && this.variant.productTypeVariantCode.toUpperCase()
      this.isDirty = true
      this.formValid = this.$refs.formbase.validate()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>
